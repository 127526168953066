import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, Injectable} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {FormGroup, FormControl, Validators } from '@angular/forms';

import {CollectionViewer, SelectionChange, DataSource} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

//MQTT Imports
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { MQTT_SERVICE_OPTIONS } from './app.module';
import { load, parse } from 'protobufjs';

interface data {
  name: string,
  path: string,
  path2: string,
  type: string,
  typeDetail: string,
  value: number
}
/**
 * Node for to-do item
 */
export class Node {
  children: Node[];
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class FlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

/**
 * The Json object for to-do list data.
 */
const TREE_DATA = {
  
};
/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<Node[]>([]);

  data() : Node[] { return this.dataChange.value; }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): Node[] {
    return Object.keys(obj).reduce<Node[]>((accumulator, key) => {
      const value = obj[key];
      const node = new Node();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: Node, name: string) {
    if (parent.children) {
      parent.children.push({item: name} as Node);
      this.dataChange.next(this.data());
    }
  }
  /** Add an item to to-do list */
  insertRootItem(item: Node) {
    let duplicate = false;
    let tempData = this.data();
    //console.log(item);

    if(!tempData.some(testItem => testItem.item == item.item)) {
      tempData.push(item);
      this.dataChange.next(tempData);
    }
  }

  updateItem(node: Node, name: string) {
    node.item = name;
    this.dataChange.next(this.data());
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ChecklistDatabase]
})
export class AppComponent {

  updateForm = new FormGroup({
    path: new FormControl('', [Validators.required]),
    path2: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    typeDetail: new FormControl('', [Validators.required]),
    newValue: new FormControl('', [Validators.required])
  })
  selectedPath = '';
  selectedButton = -1;
  Object = Object;
  openForms = {};
  openBoolForms = {};
  typeDict = {};
  data = {}
  booleanData = {};
  SparkplugPayload = parse("package org.eclipse.tahu.protobuf; message Payload { message Template { " +
            "message Parameter { optional string name = 1;optional uint32 type = 2; oneof value { uint32 int_value = 3; uint64 long_value = 4; " +
            "float float_value = 5; double double_value = 6; bool boolean_value = 7; string string_value = 8; ParameterValueExtension extension_value = 9; } " +
            "message ParameterValueExtension { extensions 1 to max; } } optional string version = 1; repeated Metric metrics = 2; " +
            "repeated Parameter parameters = 3; optional string template_ref = 4; optional bool is_definition = 5; extensions 6 to max; } " +
            "message DataSet { " +
            "message DataSetValue { oneof value { uint32 int_value = 1; uint64 long_value = 2; float float_value = 3; double double_value = 4; " +
            "bool boolean_value = 5; string string_value = 6; DataSetValueExtension extension_value = 7; } " +
            "message DataSetValueExtension { extensions 1 to max; } } " +
            "message Row { repeated DataSetValue elements = 1; extensions 2 to max; } optional uint64 num_of_columns = 1; repeated string columns = 2; " +
            "repeated uint32 types = 3; repeated Row rows = 4; extensions 5 to max; } " +
            "message PropertyValue { optional uint32 type = 1; optional bool is_null = 2;  oneof value { uint32 int_value = 3; uint64 long_value = 4; " +
            "float float_value = 5; double double_value = 6; bool boolean_value = 7; string string_value = 8; PropertySet propertyset_value = 9; " +
            "PropertySetList propertysets_value = 10; PropertyValueExtension extension_value = 11; } " +
            "message PropertyValueExtension { extensions 1 to max; } } " +
            "message PropertySet { repeated string keys = 1; repeated PropertyValue values = 2; extensions 3 to max; } " +
            "message PropertySetList { repeated PropertySet propertyset = 1; extensions 2 to max; } " +
            "message MetaData { optional bool is_multi_part = 1; optional string content_type = 2; optional uint64 size = 3; optional uint64 seq = 4; " +
            "optional string file_name = 5; optional string file_type = 6; optional string md5 = 7; optional string description = 8; extensions 9 to max; } " +
            "message Metric { optional string name = 1; optional uint64 alias = 2; optional uint64 timestamp = 3; optional uint32 datatype = 4; " +
            "optional bool is_historical = 5; optional bool is_transient = 6; optional bool is_null = 7; optional MetaData metadata = 8; " +
            "optional PropertySet properties = 9; oneof value { uint32 int_value = 10; uint64 long_value = 11; float float_value = 12; double double_value = 13; " +
            "bool boolean_value = 14; string string_value = 15; bytes bytes_value = 16; DataSet dataset_value = 17; Template template_value = 18; " +
            "MetricValueExtension extension_value = 19; } " +
            "message MetricValueExtension { extensions 1 to max; } } optional uint64 timestamp = 1; repeated Metric metrics = 2; optional uint64 seq = 3; " +
            "optional string uuid = 4; optional bytes body = 5; extensions 6 to max; } ").root

  private subscription: Subscription;
  Payload: any;
  convert(myUint8Arr){
    return String.fromCharCode.apply(null, myUint8Arr);
  }


  title = 'tagBrowser';
  opened: boolean;
  
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FlatNode, Node>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<Node, FlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: FlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<FlatNode>;

  treeFlattener: MatTreeFlattener<Node, FlatNode>;

  dataSource: MatTreeFlatDataSource<Node, FlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

  constructor(private mqttService: MqttService, private _database: ChecklistDatabase) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  getLevel = (node: FlatNode) => node.level;

  isExpandable = (node: FlatNode) => node.expandable;

  getChildren = (node: Node): Node[] => node.children;

  hasChild = (_: number, _nodeData: FlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FlatNode) => _nodeData.item === '';

  transformer = (node: Node, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
        ? existingNode
        : new FlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node:FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: FlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    console.log(parentNode);
    this._database.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: FlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, itemValue);
  }
  buildNode(path) : Node {
    let pathParts = path.split("/");
    let parent = '';
    let level = 2;
    let node = new Node;
    //console.log(pathParts);
    if(pathParts.length - level > 0) {
        let window = pathParts.slice(level,pathParts.length)
        let output = window.join('/')
        //console.log(output);
        let children = this.buildNode('/'+output);
        node.item = pathParts[1]
        node.children = [children];
    }
    else {
      node.item = pathParts[1];
    }
    return node
  }
  checkPath(path) {
    let part = this.buildNode(path) as Node;
    this._database.insertRootItem(part);
    
  }
  numericForm(path) {
    if(path in this.openForms) {
      return this.openForms[path];
    }
    else {
      return false;
    }
  }
  booleanForm(path) {
    if(path in this.openBoolForms) {
      return this.openBoolForms[path];
    }
    else {
      return false;
    }
  }
  toggleFormVisible(path) {
    if (path in this.openForms) {
      this.openForms[path] = !this.openForms[path];
    }
    else {
      this.openForms[path] = true;
    }
  }
  toggleBoolFormVisible(path) {
    if (path in this.openBoolForms) {
      this.openBoolForms[path] = !this.openBoolForms[path];
    }
    else {
      this.openBoolForms[path] = true;
    }
  }
  boolToggle($event, value: number) {
    if($event.value == "On") {
      this.selectedButton = 1;
    }
    else {
      this.selectedButton = 0;
    }
  }
  updateData() {
    let path = this.updateForm.get('path').value;
    let path2 = this.updateForm.get('path2').value;
    let value = this.updateForm.get('newValue').value;
    let name = this.updateForm.get('name').value;
    let typeDetail = this.updateForm.get('typeDetail').value;
    path = path.replace("DDATA", "DCMD");
    var metric = {};
    
    let Payload = this.SparkplugPayload.lookupType('org.eclipse.tahu.protobuf.Payload');
    console.log(typeDetail);
    if(typeDetail == 'doubleValue') {
      metric = {
        "name" : name,
        "datatype" : 9,
        "doubleValue" : parseFloat(value)
      }
      
    }
    else if(typeDetail =='floatValue') {
      metric = {
        "name" : name,
        "datatype" : 9,
        "floatValue" : parseFloat(value)
      }
    }
    else if(typeDetail == 'intValue') {
      metric = {
        "name" : name,
        "type" : 'int32',
        "datatype" : 3,
        "intValue" : parseInt(value)
      }
    }
    else if(typeDetail == 'booleanValue') {
      metric = {
        "name" : name,
        "type" : 'boolean',
        "datatype" : 11,
        "booleanValue" : Boolean(this.selectedButton)
      }
    }
     
    
    var payload =  {
      "timestamp" : new Date().getTime(),
      "metrics" : [
          metric
      ],
      "seq" : 1
    };
    console.log(payload);
    let encoded = Payload.encode(payload).finish();
    var decoder = new TextDecoder('utf8');
    var b64encoded = decoder.decode(encoded);
    console.log(String(b64encoded));
    console.log("SENDING DATA TO " + path)
    this.mqttService.publish(path, encoded).subscribe((err) => console.log(err));;
    console.log("Publishing to " + path)
  }
  ngOnInit() {
    this.subscription = this.mqttService.observe("spBv1.0/#").subscribe((message: IMqttMessage) => {
      this.Payload = this.SparkplugPayload.lookupType('org.eclipse.tahu.protobuf.Payload');
      //console.log(message);
      //Get group from message.topic
      let name_parts = message.topic.split("DDATA")
      let name = name_parts[name_parts.length - 1]
      if(name.length > 1) {
        try {
          var spp = this.Payload.decode(message.payload)
          console.log(spp);
          spp.metrics.forEach(metric => {
          var path = name_parts[1]+'/'+metric.name;
          if(metric.doubleValue){
            this.typeDict[path] = 'doubleValue';
            this.data[path] = {'name': metric.name, 'path': message.topic,'path2': name_parts[1] ,'type':metric.datatype, 'typeDetail': 'doubleValue','value': metric.doubleValue};
            this.checkPath(path);
            //this.addNewItem(newNode);
            //this.dataSource.data.push(this.addNode(name_parts[1], this.dataSource.data));
          } 
          if(metric.intValue){
            this.typeDict[path] = 'intValue';
            this.data[path] = {'name': metric.name, 'path': message.topic,'path2': name_parts[1] ,'type':metric.datatype, 'typeDetail': 'intValue', 'value': metric.intValue};
            this.checkPath(path);
            /*let newNode = {'name': ''}//;this.addNode(name_parts[1], this.dataSource.data);
            if(newNode.name != '') {
              this.dataSource.data.push(newNode);
              this.dataSource._data.next(this.dataSource.data);
              this.treeControl.expand(newNode);
            }*/
          } 
          if(metric.floatValue){
            this.typeDict[path] = 'floatValue';
            this.data[path] = {'name': metric.name, 'path': message.topic,'path2': name_parts[1] ,'type':metric.datatype, 'typeDetail': 'floatValue','value': metric.floatValue};
            this.checkPath(path);
            //this.addNewItem(newNode);
            //this.dataSource.data.push(this.addNode(name_parts[1], this.dataSource.data));
          } 
          if(metric.datatype == 11){
            this.typeDict[path] = 'booleanValue';
            //console.log("ADDED BOOLEAN " + metric.name);
            this.booleanData[path] = {'name': metric.name, 'path': message.topic,'path2': name_parts[1] ,'type':metric.datatype, 'typeDetail': 'booleanValue','value': metric.booleanValue};
            this.checkPath(path);
          }

        });
        }
        catch {
          console.log("Unable to parse")
        }
        
      }
    });
}

}
