<mat-toolbar>
  <button mat-button (click)="opened=!opened" class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>

  <span>Tag Browser</span>
  <span class="example-spacer"></span>
  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>favorite</mat-icon>
  </button>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav mode="over" [(opened)]="opened">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
                      [checked]="checklistSelection.isSelected(node)"
                      (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
      </mat-tree-node>
    
      <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-form-field>
          <mat-label>New item...</mat-label>
          <input matInput #itemValue placeholder="Ex. Lettuce">
        </mat-form-field>
        <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
      </mat-tree-node>
    
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.item">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)"
                      [indeterminate]="descendantsPartiallySelected(node)"
                      (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
      </mat-tree-node>
    </mat-tree>
    
   
  </mat-sidenav>
  <mat-sidenav-content>

    <mat-card *ngFor="let each of Object.keys(data)" ><p (click)="toggleFormVisible(each)"> {{each }} {{data[each].value | number: '1.1-2'}}  </p>   

        <ng-container *ngIf="numericForm(each)">
          <form [formGroup]="updateForm" (ngSubmit)="updateData()">
            <input type="hidden" formControlName="path" [ngModel]="data[each].path">
            <input type="hidden" formControlName="path2" [ngModel]="data[each].path2">
            <input type="hidden" formControlName="name" [ngModel]="data[each].name">
            <input type="hidden" formControlName="type" [ngModel]="data[each].type">
            <input type="hidden" formControlName="typeDetail" [ngModel]="data[each].typeDetail">
            <mat-form-field  appearance="standard">
              <mat-label>Setpoint</mat-label>
              <input formControlName="newValue" matInput placeholder="{{data[each].value}}">

              
              <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
              <mat-hint></mat-hint>
            </mat-form-field>
            <button type="submit" mat-raised-button color="primary">Update</button>
          </form>
        </ng-container>
    </mat-card>

    <mat-card *ngFor="let each of Object.keys(booleanData)" ><p (click)="toggleBoolFormVisible(each)"> {{each }} {{booleanData[each].value}}  </p>
      <ng-container *ngIf="booleanForm(each)">
        <form [formGroup]="updateForm" (ngSubmit)="updateData()">
          <input type="hidden" formControlName="path" [ngModel]="booleanData[each].path">
          <input type="hidden" formControlName="path2" [ngModel]="booleanData[each].path2">
          <input type="hidden" formControlName="name" [ngModel]="booleanData[each].name">
          <input type="hidden" formControlName="type" [ngModel]="booleanData[each].type">
          <input type="hidden" formControlName="typeDetail" [ngModel]="booleanData[each].typeDetail">

          <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="Off" (change)="boolToggle($event, 0)" [checked]="!booleanData[each].value">Off</mat-button-toggle>
            <mat-button-toggle value="On" (change)="boolToggle($event, 0)" [checked]="booleanData[each].value">On</mat-button-toggle>
          </mat-button-toggle-group>
          <button type="submit" mat-raised-button color="primary">Update</button>
        </form>
      </ng-container>
    </mat-card>


    

  </mat-sidenav-content>
</mat-sidenav-container>
